import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CardDialog from '~/components/CardDialog';
import EditorFormik from '~/components/communications/EmailCommunicationCard/EmailEditor/EditorFormik';
import Button from '~/components/core/Atomic/Buttons/Button';
import Divider from '~/components/core/Atomic/Divider/Divider';
import CancelButton from '~/components/core/Buttons/CancelButton';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import Heading from '~/components/core/TextComponents/Heading';
import Text from '~/components/core/TextComponents/Text';
import { buildGetTemplateTokensQueryParams } from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/utils';
import GenericTemplateContentContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateCard/GenericTemplateContentContainer';
import PencilIcon from '~/components/icons/PencilIcon';
import type { HandleUpdateDyteConfiguration } from '~/components/SystemConfiguration/Communications/DyteVideoConfigurationTab/types';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';

interface Props {
  handleUpdateConfiguration: HandleUpdateDyteConfiguration;
  emailTitleTemplate: string;
  emailBodyTemplate: string;
  smsTemplate: string;
}

interface FormValues {
  title_template?: string | undefined;
  body_template: string;
}

interface TemplateSectionProps {
  onEdit: () => void;
  titleTemplate?: string;
  bodyTemplate: string;
  title: string;
}
const TemplateSection: React.FC<TemplateSectionProps> = ({ onEdit, titleTemplate, bodyTemplate, title }) => {
  return (
    <div className="my-20 flex flex-col items-center justify-between">
      <div className="flex w-full items-center justify-between">
        <Text variant={Text.VARIANTS.XS} colorVariant={Text.COLOR_VARIANTS.SECONDARY} weight={Text.WEIGHTS.SEMI_BOLD}>
          {title}
        </Text>
        <Button variant="text" color="primary" onClick={onEdit} startIcon={<PencilIcon iconColor="currentColor" />}>
          Edit
        </Button>
      </div>
      <Divider className="w-full" />
      <GenericTemplateContentContainer titleTemplate={titleTemplate} bodyTemplate={bodyTemplate} className="w-full" />
    </div>
  );
};

const VideoGenericTemplatesConfiguration: React.FC<Props> = ({
  emailTitleTemplate,
  emailBodyTemplate,
  smsTemplate,
  handleUpdateConfiguration,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const [editTemplateType, setEditTemplateType] = React.useState<'Sms' | 'Email' | null>(null);
  const [isUpdatingConfiguration, setIsUpdatingConfiguration] = React.useState(false);
  const { data, isLoading } = useDataFetcher(
    `/api/v1/generic_templates/organization/${organization.id}/template/tokens?${buildGetTemplateTokensQueryParams({
      lobs: [],
      is_claim_level: true,
      feature_specific: 'video_calls',
    })}`,
    {}
  );
  const onClose = () => {
    setEditTemplateType(null);
  };

  const suggestions =
    data?.tokens && !isLoading
      ? data.tokens.map((token: { display_name: string; token_key: string }) => ({
          label: token.display_name,
          id: token.token_key,
        }))
      : [];
  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <Heading variant={Heading.TYPES.H3}>Customize Video Call invitation</Heading>
        </div>
        <Text variant={Text.VARIANTS.XS} colorVariant={Text.COLOR_VARIANTS.PRIMARY} className="opacity-50">
          Edit templates for email and sms video calls invitations
        </Text>
        {isUpdatingConfiguration ? (
          <SkeletonTable rowsCount={2} columnsCount={1} maxHeight="100px" />
        ) : (
          <>
            <TemplateSection
              onEdit={() => {
                setEditTemplateType('Email');
              }}
              title="Email Invitation"
              titleTemplate={emailTitleTemplate}
              bodyTemplate={emailBodyTemplate}
            />
            <TemplateSection
              onEdit={() => {
                setEditTemplateType('Sms');
              }}
              title="SMS Invitation"
              bodyTemplate={smsTemplate}
            />
          </>
        )}
      </div>
      {editTemplateType ? (
        <Formik<FormValues>
          initialValues={
            editTemplateType === 'Email'
              ? {
                  title_template: emailTitleTemplate,
                  body_template: emailBodyTemplate,
                }
              : { body_template: smsTemplate }
          }
          validationSchema={Yup.object().shape({
            title_template: Yup.string().nullable(),
            body_template: Yup.string().required('Required'),
          })}
          onSubmit={async (values) => {
            setIsUpdatingConfiguration(true);
            if (editTemplateType === 'Email') {
              await handleUpdateConfiguration({
                email_title_template: values.title_template,
                email_body_template: values.body_template,
              });
            }
            if (editTemplateType === 'Sms') {
              await handleUpdateConfiguration({ sms_template: values.body_template });
            }
            onClose();
            setIsUpdatingConfiguration(false);
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <CardDialog
              isDialog
              title={`Edit ${editTemplateType} Template`}
              onClose={onClose}
              preventClose={isSubmitting}
              fullWidth
              maxWidth="md"
            >
              {editTemplateType === 'Email' ? (
                <>
                  <EditorFormik
                    label="Email Subject"
                    id="title_template"
                    autoFocus
                    displayConfig={{ suggestions: !!suggestions }}
                    suggestionOptions={suggestions}
                  />
                  <EditorFormik
                    label="Email Body"
                    id="body_template"
                    classNameEditor="min-h-[150px] overflow-auto"
                    autoFocus
                    displayConfig={{
                      font: true,
                      textColor: true,
                      textStyle: true,
                      align: true,
                      link: true,
                      suggestions: !!suggestions,
                    }}
                    suggestionOptions={suggestions}
                  />
                </>
              ) : (
                <EditorFormik
                  label="SMS Body"
                  id="body_template"
                  classNameEditor="min-h-[150px] overflow-auto"
                  autoFocus
                  displayConfig={{
                    suggestions: !!suggestions,
                  }}
                  suggestionOptions={suggestions}
                />
              )}

              <div className="mt-20 flex w-full justify-end">
                <CancelButton disabled={isSubmitting} onClick={onClose} />
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
                  Save
                </Button>
              </div>
            </CardDialog>
          )}
        </Formik>
      ) : null}
    </>
  );
};

export default VideoGenericTemplatesConfiguration;
