import React from 'react';
import axios from 'axios';
import type { FormikHelpers, FormikProps, FormikValues } from 'formik';
import { Formik } from 'formik';

import CardDialog from '~/components/CardDialog';
import Button from '~/components/core/Atomic/Buttons/Button';
import ButtonsContainer from '~/components/core/Atomic/Buttons/ButtonsContainer';
import CancelButton from '~/components/core/Buttons/CancelButton';
import { SubReservesFormik } from '~/components/exposures/SubReservesFormik';
import useOrganization from '~/components/OrganizationContext';
import type {
  StatReserveConfiguration,
  StatSubReserveConfig,
} from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationPage';
import { MonetaryValueTextFieldFormik } from '~/components/TextFieldFormik';
import { reportAxiosError } from '~/Utils';

const FIELD_IDS = {
  EXPENSES_RESERVE: 'expenses_reserve_amount',
  EXPENSES_SUB_RESERVES: 'expenses_sub_reserves_amounts',
  INDEMNITY_RESERVE: 'indemnity_reserve_amount',
  INDEMNITY_SUB_RESERVES: 'indemnity_sub_reserves_amounts',
};
interface StatSubReservesFormikValues {
  id?: number;
  indemnity_reserve_amount: number;
  expenses_reserve_amount: number;
  indemnity_sub_reserves_amounts?: Record<string, number>;
  expenses_sub_reserves_amounts?: Record<string, number>;
}

export interface StatReserveUpsertDialogProps {
  statReserveConfig: StatReserveConfiguration;
  onSubmit: () => void;
  onClose: () => void;
}
const StatReserveUpsertDialog: React.FC<StatReserveUpsertDialogProps> = ({ statReserveConfig, onSubmit, onClose }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationId } = useOrganization();

  const getSerializedStatReserveConfig = ({
    statReserveConfig,
    formikSubReservesValues,
  }: {
    statReserveConfig: StatReserveConfiguration;
    formikSubReservesValues: StatSubReservesFormikValues;
  }) => {
    const config: StatReserveConfiguration = {
      ...statReserveConfig,
      indemnity_reserve_amount: formikSubReservesValues.indemnity_reserve_amount,
      expenses_reserve_amount: formikSubReservesValues.expenses_reserve_amount,
    };
    if (formikSubReservesValues.expenses_sub_reserves_amounts && statReserveConfig.expenses_sub_reserves_amounts) {
      config.expenses_sub_reserves_amounts = {};
      for (const key in formikSubReservesValues.expenses_sub_reserves_amounts) {
        config.expenses_sub_reserves_amounts[key] = {
          amount: formikSubReservesValues.expenses_sub_reserves_amounts[key],
          display_name: statReserveConfig.expenses_sub_reserves_amounts[key].display_name,
        };
      }
    }

    if (formikSubReservesValues.indemnity_sub_reserves_amounts && statReserveConfig.indemnity_sub_reserves_amounts) {
      config.indemnity_sub_reserves_amounts = {};
      for (const key in formikSubReservesValues.indemnity_sub_reserves_amounts) {
        config.indemnity_sub_reserves_amounts[key] = {
          amount: formikSubReservesValues.indemnity_sub_reserves_amounts[key],
          display_name: statReserveConfig.indemnity_sub_reserves_amounts[key].display_name,
        };
      }
    }
    return config;
  };
  const handleSubmit = async (
    values: StatSubReservesFormikValues,
    formikHelpers: FormikHelpers<StatSubReservesFormikValues>
  ) => {
    try {
      if (statReserveConfig.id) {
        await axios.patch(
          `/api/v1/organizations/${organizationId}/stat_reserve_configuration/${statReserveConfig.id}`,
          getSerializedStatReserveConfig({ statReserveConfig, formikSubReservesValues: values })
        );
      } else {
        await axios.post(
          `/api/v1/organizations/${organizationId}/stat_reserve_configuration`,
          getSerializedStatReserveConfig({ statReserveConfig, formikSubReservesValues: values })
        );
      }
      onSubmit();
    } catch (error) {
      formikHelpers.setSubmitting(false);
      await reportAxiosError(error);
    }
  };

  const initialIndemnitySubReservesAmounts: Record<string, number> = {};

  if (statReserveConfig.indemnity_sub_reserves_amounts) {
    Object.entries(statReserveConfig.indemnity_sub_reserves_amounts).forEach(([key, subReserve]) => {
      initialIndemnitySubReservesAmounts[key] = subReserve.amount;
    });
  }

  const expensesIndemnitySubReservesAmounts: Record<string, number> = {};

  if (statReserveConfig.expenses_sub_reserves_amounts) {
    Object.entries(statReserveConfig.expenses_sub_reserves_amounts).forEach(([key, subReserve]) => {
      expensesIndemnitySubReservesAmounts[key] = subReserve.amount;
    });
  }

  const initialValues: StatSubReservesFormikValues = {
    id: statReserveConfig.id,
    indemnity_reserve_amount: statReserveConfig.indemnity_reserve_amount,
    expenses_reserve_amount: statReserveConfig.expenses_reserve_amount,
    indemnity_sub_reserves_amounts: initialIndemnitySubReservesAmounts,
    expenses_sub_reserves_amounts: expensesIndemnitySubReservesAmounts,
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formikProps: FormikProps<FormikValues>) => {
        const { handleSubmit, isSubmitting } = formikProps;

        return (
          <CardDialog isDialog title={statReserveConfig.coverage_display_name} fullWidth onClose={onClose}>
            <div className="grid grid-cols-2 gap-40">
              <div className="p-20">
                <StatReserveSubReservesFormik
                  subReservesFieldId={FIELD_IDS.INDEMNITY_SUB_RESERVES}
                  totalFieldId={FIELD_IDS.INDEMNITY_RESERVE}
                  isSubReservesConfigEnabled={statReserveConfig.coverage_is_indemnity_sub_reserves_config_enabled}
                  subReservesAmountsDict={statReserveConfig.indemnity_sub_reserves_amounts}
                  fieldLabel="Indemnity"
                />
              </div>
              <div className="p-20">
                <StatReserveSubReservesFormik
                  subReservesFieldId={FIELD_IDS.EXPENSES_SUB_RESERVES}
                  totalFieldId={FIELD_IDS.EXPENSES_RESERVE}
                  isSubReservesConfigEnabled={statReserveConfig.is_expenses_sub_reserves_config_enabled}
                  subReservesAmountsDict={statReserveConfig.expenses_sub_reserves_amounts}
                  fieldLabel="Expenses"
                />
              </div>
            </div>
            <ButtonsContainer className="mt-40">
              <CancelButton disabled={isSubmitting} onClick={onClose} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
                Save
              </Button>
            </ButtonsContainer>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

interface StatReservesSubReservesFormikProps {
  isSubReservesConfigEnabled: boolean;
  subReservesAmountsDict: Record<string, StatSubReserveConfig> | undefined;
  subReservesFieldId: string;
  totalFieldId: string;
  fieldLabel: string;
}
const StatReserveSubReservesFormik: React.FC<StatReservesSubReservesFormikProps> = ({
  isSubReservesConfigEnabled,
  subReservesAmountsDict,
  subReservesFieldId,
  totalFieldId,
  fieldLabel,
}) => {
  return (
    <div>
      {isSubReservesConfigEnabled && subReservesAmountsDict ? (
        <SubReservesFormik
          subReservesFieldId={subReservesFieldId}
          totalFieldId={totalFieldId}
          totalFieldLabel={`Total ${fieldLabel} Reserve`}
          subReservesConfigMap={new Map<string, StatSubReserveConfig>(Object.entries(subReservesAmountsDict))}
          displayNameKey="display_name"
        />
      ) : (
        <div className="flex flex-col">
          <MonetaryValueTextFieldFormik
            id={totalFieldId}
            label={`${fieldLabel} Reserve Amount`}
            allowNegative={false}
          />
        </div>
      )}
    </div>
  );
};

export default StatReserveUpsertDialog;
