import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';

import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import TextField from '~/components/core/Molecules/Fields/TextField';

import { CloseIcon, SearchIcon } from '../../icons';

import styles from './SimpleSearchField.module.scss';

const SimpleSearchField = ({ onChange, label, placeholder, renderClearButton }) => {
  const [id] = useState(_uniqueId('prefix-'));
  const [searchText, setSearchText] = useState('');

  return (
    <TextField
      value={searchText}
      size="small"
      onChange={(value) => {
        setSearchText(value);
        onChange(value);
      }}
      id={id}
      label={label}
      fullWidth
      InputProps={{
        placeholder,
        endAdornment: (
          <>
            {renderClearButton && searchText.length > 0 ? (
              <IconButton
                size="small"
                title="Clear"
                onClick={() => {
                  setSearchText('');
                  onChange('');
                }}
              >
                <CloseIcon height={10} width={10} />
              </IconButton>
            ) : null}
            <SearchIcon size={13} className={styles.searchIcon} />
          </>
        ),
      }}
    />
  );
};

SimpleSearchField.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  renderClearButton: PropTypes.bool,
};

export default SimpleSearchField;
